import React from "react"
import { graphql } from "gatsby"
import ProjectLayout from "../components/projects/projectLayout"
import * as styles from "../styles/projects.module.css"

const ProjectDetails = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <ProjectLayout state={false} data={frontmatter}>
      <div
        className={
          styles.contentArea + ` parallax2 h-100 ` + styles.itemContentArea
        }
        // style={{ width: "1145px" }}
      >
        <div className={styles.titleDiv + ` px-3 w-100 pt-0 pb-2`}>
          <div className="col">
            <hr className={styles.tophr} style={{ padding: "1px" }} />
          </div>
          <h1>{frontmatter.name}</h1>
          <div className="col">
            <hr className={styles.tophr} style={{ padding: "1px" }} />
          </div>
        </div>

        <div className="bg-transparent col position-relative mx-0 p-0 w-100 mt-4 mb-5">
          <div
            className={styles.cardDiv + ` card shadow-sm bg-transparent mx-4`}
          >
            <div style={{ display: "grid" }}>
              <div
                style={{
                  gridArea: "1/1",
                  position: "relative",
                  display: "grid",
                }}
                className={styles.bgLayer}
              ></div>

              <div
                className={styles.cardBody + ` card-body m-0 p-2 w-100`}
                style={{
                  gridArea: "1/1",
                  position: "relative",
                  display: "grid",
                }}
              >
                <div className={styles.descBox + ` p-4 d-flex`}>
                  <div className="p-0 m-0">
                    <h4 className="mb-3">
                      <b>Description:</b>
                    </h4>
                    <p>{frontmatter.desc}</p>
                    <br />
                    <h4 className="mb-3">
                      <b>Technologies:</b>
                    </h4>
                    <ul>
                      {frontmatter.tech.map(t => (
                        <li>{t}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="m-0 w-100 mb-5 px-4 pt-0 pb-2" id="html">
          <div
            className="mdHtml"
            style={{ maxWidth: "720px" }}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </div>
      </div>
    </ProjectLayout>
  )
}

export default ProjectDetails

export const query = graphql`
  query MyQuery($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        name
        category
        tools
        link
        link2
        link3
        desc
        tech
      }
      html
    }
  }
`
